import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Link } from "react-router-dom";
export const Country = ({ index }) => {

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        centerPadding: '0',
        responsive: [
            {
                no: 1,
                breakpoint: 1600,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                no: 2,
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                no: 3,
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                no: 4,
                breakpoint: 560,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const CountrySlide = [
        {
            sno: 1,
            bgImg: "images/country-australia.jpg",
            title: "Australia",
            link: "/australia"
        },
        {
            sno: 2,
            bgImg: "images/country-usa.jpg",
            title: "USA",
            link: "/usa"
        },
        {
            sno: 3,
            bgImg: "images/country-uk.jpg",
            title: "Uk",
            link: "/uk"
        },
        {
            sno: 5,
            bgImg: "images/country-canada.jpg",
            title: "Canada",
            link: "/canada"
        },
        {
            sno: 6,
            bgImg: "images/country-dubai.jpg",
            title: "Dubai",
            link: "/dubai"
        }
    ]
    return (
        <section className="py-5 bg-primary country-section">
            <div className="container" >
                <div className="title text-center text-light pb-3">
                    <h1 className="mb-0 mt-0">Where do you want to study?</h1>
                    <p className="mt-0 mb-3">We are here to recommend you with the Best Universities in Best Destination to help build your career.</p>
                </div>
                <Slider {...settings}>
                    {
                        CountrySlide.map((CountrySlide) => {
                            return (

                                <div key={CountrySlide.id}>
                                    <div className="country-box">

                                        <LazyLoadImage
                                            alt={CountrySlide.title}
                                            effect="blur"
                                            src={CountrySlide.bgImg}
                                            // height="300px"
                                            className="main-img"
                                            key={CountrySlide.sno}
                                        // placeholderSrc={slide.thumbImg}
                                        ></LazyLoadImage>

                                        <div className="title">
                                            <h2 className="text-light my-0 ">{CountrySlide.title}</h2>
                                            <Link to={CountrySlide.link} className="no-bg-btn px-0"> Read More <img src="images/long-arrow.svg" /></Link>
                                        </div>
                                    </div>
                                </div>

                            )
                        })}

                </Slider>
            </div >
        </section >

    )
}
