import React from 'react'

export const SliderData = [
    {
        image: "images/slider1.jpg",
        thumbImg: "images/slider1-thumb.jpg",
        h1: "Australia",
        h2: 'Study In',
        flag: 'images/slider-person.png',
        desc: "Australia has become one of the biggest and best destinations for Nepali students to study. The country hosts over 22,000 courses across 1,100 institutions. Nevertheless, 7 of the world’s top 100 universities are also in Australia. Therefore, Australia is the perfect destination to pursue your career goals. Furthermore, it’s a democratic country and offers great healthcare along with world-class educational institutions and is a safe location for all international students with a high standard of living culture.",
        link: '/australia'
    },
    {
        image: "images/usa-slider.jpg",
        thumbImg: "images/usa-slider-thumb.jpg",
        h1: "USA",
        h2: 'Study In',
        flag: '',
        desc: "As we all know, the USA is crowned as the Land of Opportunities. Most of the world’s biggest names are based in the USA. One of the main reasons is its education system that suits and takes out the most of a student. Currently, the country hosts more than 10,00,000 international students and welcomes international students every year with open arms.",
        link: '/usa'

    },
    {
        image: "images/uk-slider.jpg",
        thumbImg: "images/uk-slider-thumb.jpg",
        h1: "UK",
        h2: 'Study In',
        flag: '',
        desc: "The UK is renowned as one of the world’s best destinations for higher education. All the Universities of the UK have excellent academic excellence and quality in their education system. As of 2020, there were 131 World-class Universities. Among these Universities, 4 of them fall under the roof of World’s Top 6 Universities. Furthermore, the country offers comprehensive support for all international students. Likewise, the country has a history of teaching world’s greatest leaders, thinkers, scientists and  names. ",
        link: '/uk'

    }
]