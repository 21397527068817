import React from 'react'
import { Link } from 'react-router-dom'

const classeContent = [
    {
        title: "IELTS",
        subtitle: "Get Higher Band Scores",
        description: "The International English Language Test System (IELTS) established in 1989 is an standardized and widely recognized English language test for study, migration and work. The main motive of this test is to evaluate the English language proficiency of non-native English speakers such as we Nepalese people.",
        link: "/ielts",
        bg: "#821515"

    },
    {
        title: "PTE",
        subtitle: "Get Higher Band Scores",
        description: "Pearson Tests of English (PTE) is a fully computerized English language test for international study and immigration. Likewise, the test is completely powered by AI technology and unlike the conventional grading system, the PTE grading is completely objective and free from human biases and thoughts.",
        link: "/pte",
        bg: "#156882"

    },
    // {
    //     title: "TOFEL",
    //     subtitle: "Get Higher Band Scores",
    //     description: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.It is a long established fact ",
    //     link: "/tofel",
    //     bg: "#821542"

    // },
    // {
    //     title: "GRE",
    //     subtitle: "Get Higher Band Scores",
    //     description: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    //     link: "/comming",
    //     bg: "#158268"
    // },
    // {
    //     title: "GMAT",
    //     subtitle: "Get Higher Band Scores",
    //     description: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.",
    //     link: "/comming",
    //     bg: "#9B6D12"
    // },
]


export const Classes = () => {

    return (
        <section className="class-section py-5">
            <div className="container">


                <div className="title text-center">
                    <h1 className="mt-0">Test Preparation Classes</h1>
                </div>
                <div className='classes-box-outer'>
                    {classeContent.map((classeContent) => {
                        return (
                            <div className="text-light py-4 px-4 class-box" style={{ background: classeContent.bg }} >
                                <h1 className="mt-0 mb-0">{classeContent.title}</h1>
                                <h3 className="mt-0">{classeContent.subtitle}</h3>
                                <div className="line bg-light"></div>
                                <p className="my-3">{classeContent.description}</p>
                                <Link to={classeContent.link} className="no-bg-btn pl-0"> Read More <img src="images/long-arrow.svg" /></Link>
                            </div>
                        )
                    })}

                </div>
            </div>
        </section>
    )
}
