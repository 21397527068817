import React from 'react'
import { BiMenu } from 'react-icons/bi';
import { BiX } from 'react-icons/bi';
import { useState } from 'react';
import { BiChevronDown } from "react-icons/bi";
import { Link } from 'react-router-dom';

export const Menu = () => {

    const [showMenu, setShowMenu] = useState(false);
    const [isActive, setisActive] = useState(false);
    const [isActive1, setisActive1] = useState(false);

    let menu;

    if (showMenu) {
        menu = "show"
        document.body.classList.add("no-scroll")
    }
    else {
        menu = "hide"
        document.body.classList.remove("no-scroll")
    }

    return (
        <section className='menu'>
            <div className="burger-icon mobile-menu full-height burger-outer">
                <div>
                    <BiMenu className="bx-lg"
                        onClick={() => {
                            setShowMenu(!showMenu)
                        }}
                    />
                </div>
            </div>
            <div className={menu}>
                <nav className="nav-bar-box">
                    <div className="d-flex logo-time">
                        <div><img src="images/logo-light.png" className="mb-3" height="80px"></img></div>
                        <div><BiX className="bix" onClick={() => {
                            setShowMenu(false);
                        }} /></div>
                    </div>
                    <ul className="nav-bar-list" >
                        <li className="nav-bar">
                            <Link to="/" className="nav-link" onClick={() => {
                                setShowMenu(false);
                            }}>Home</Link>
                        </li>
                        <li className="nav-bar">
                            <a className="nav-link" onClick={() => {
                                setisActive(true)
                                setisActive1(false)
                            }}>Study Abroad <BiChevronDown></BiChevronDown></a>
                            {isActive && (
                                <ul>
                                    <div>
                                        <h2 className='mb-1'>Study Abroad</h2>
                                        <p className='mb-2'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                        <ul className='inner-dropdown-list' onClick={() => {
                                            setShowMenu(false);
                                        }}>
                                            <li>
                                                <Link to="/australia">
                                                    <img src='images/australia-flag.jpg'></img><br />
                                                    Australia
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/usa">
                                                    <img src='images/usa-flag.jpg'></img><br />
                                                    USA
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/canada">
                                                    <img src='images/canada-flag.jpg'></img><br />
                                                    Canada
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/uk">
                                                    <img src='images/uk-flag.jpg'></img><br />
                                                    UK
                                                </Link>
                                            </li>
                                            {
                                                // <li>
                                                //     <Link to="/comming">
                                                //         <img src='images/japan-flag.jpg'></img><br />
                                                //         Japan
                                                //     </Link>
                                                // </li>
                                            }
                                            <li>
                                                <Link to="/dubai">
                                                    <img src='images/dubai-flag.jpg'></img><br />
                                                    Dubai
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </ul>
                            )}
                        </li>
                        <li className="nav-bar">
                            <a className="nav-link" onClick={() => {
                                setisActive1(true)
                                setisActive(false)
                            }}>Test Preparation <BiChevronDown></BiChevronDown></a>
                            {isActive1 && (
                                <ul onClick={() => {
                                    setShowMenu(false);
                                }}>
                                    <div>
                                        <h2 className='mb-1'>Test Preparation</h2>
                                        <p className='mb-2'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                        <div className='inner-dropdown-list'>
                                            <li><Link to="/ielts">IELTS</Link></li>
                                            <li><Link to="/pte">PTE</Link></li>
                                            {
                                                /**
                                                <li><Link to="/comming">OET</Link></li>
                                                <li><Link to="/tofel">TOFEL</Link></li>
                                                <li><Link to="/comming">GRE</Link></li>
                                                 */
                                            }
                                        </div>

                                    </div>
                                </ul>
                            )}
                        </li>
                        <li className="nav-bar">
                            <Link to="/comming" className="nav-link" onClick={() => {
                                setShowMenu(false);
                            }}>Scholarships</Link>
                        </li>
                        <li className="nav-bar">
                            <Link to="/about" className="nav-link" onClick={() => {
                                setShowMenu(false);
                            }}>About</Link>
                        </li>
                        <li className="nav-bar">
                            <Link to="/staff" className="nav-link" onClick={() => {
                                setShowMenu(false);
                            }}>Out Team</Link>
                        </li>
                        <li className="nav-bar">
                            <Link to="/gallery" className="nav-link" onClick={() => {
                                setShowMenu(false);
                            }}>Gallery</Link>
                        </li>
                        <li className="nav-bar">
                            <Link to="/contact" className="nav-link" onClick={() => {
                                setShowMenu(false);
                            }}>Contact</Link>
                        </li>

                    </ul>
                </nav>
            </div>

            <div className="web-menu">
                <nav className="navbar-web-outer">
                    <ul className="navbar-web-list my-0">
                        <li className="navbar-web">
                            <Link to="/" className="navbar-web-link" onClick={() => {
                                setShowMenu(false);
                            }}>Home</Link>
                        </li>

                        <li className="navbar-web">
                            <a className="navbar-web-link" onClick={() => {
                                setShowMenu(!showMenu)
                                setisActive(true)
                                setisActive1(false)
                            }} >Study Abroad <BiChevronDown></BiChevronDown></a>
                        </li>
                        <li className="navbar-web">
                            <a className="navbar-web-link" onClick={() => {
                                setShowMenu(!showMenu)
                                setisActive1(true)
                                setisActive(false)
                            }}>Test Preparation <BiChevronDown></BiChevronDown></a>
                        </li>
                        <li className="navbar-web">
                            <Link to="/comming" className="navbar-web-link" onClick={() => {
                                setShowMenu(false);
                            }}>Scholarships</Link>
                        </li>
                        <li className="navbar-web">
                            <Link to="/about" className="navbar-web-link" onClick={() => {
                                setShowMenu(false);
                            }}>About</Link>
                        </li>
                        <li className="navbar-web">
                            <Link to="/staff" className="navbar-web-link" onClick={() => {
                                setShowMenu(false);
                            }}>Our Team</Link>
                        </li>
                        <li className="navbar-web">
                            <Link to="/gallery" className="navbar-web-link" onClick={() => {
                                setShowMenu(false);
                            }}>Gallery</Link>
                        </li>
                        <li className="navbar-web">
                            <Link to="/contact" className="navbar-web-link" onClick={() => {
                                setShowMenu(false);
                            }}>Contact</Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </section>

    )
}
