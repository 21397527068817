import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
export const PteClasses = () => {
    window.scrollTo(0, 0);
    return (
        <div>
            <section className='bg-primary text-light py-5 text-center'>
                <h1 className='my-0'>PTE</h1>
                <h3 className='my-0'>Get Higher Band Scores In PTE</h3>
            </section>
            <section className='py-5 bg-light text-center'>
                <div className='container'>
                    <h2 className='text-primary mt-0'>What IS PTE</h2>
                    <p>Pearson Tests of English (PTE) is a fully computerized English language test for international study and immigration. Likewise, the test is completely powered by AI technology and unlike the conventional grading system, the PTE grading is completely objective and free from human biases and thoughts.</p>
                    <p>Similar to IELTS, PTE also foresees the capability of Reading, Listening, Speaking and Writing of a student. In some scenarios, students can go for PTE as an alternative to IELTS. Countries like Australia accept both IELTS and PTE tests. Furthermore, PTE enrolled students have a benefit. PTE Tests results are typically published within 48 hours of taking the test while IELTS takes much longer. Plus, PTE tests are taken in two parts; Reading and Listening, Speaking and Writing.</p>
                </div>
            </section>
            <section className='py-5 class-structure'>
                <div className='container'>
                    <h2 className='text-primary text-center'>The PTE test structure</h2>
                    <div className='d-grid'>
                        <div>
                            <LazyLoadImage
                                src='images/pte1.jpg'
                                alt='img'
                                effect='blur'
                            ></LazyLoadImage>

                        </div>
                        <div className='d-flex'>
                            <div>
                                <h3 className='text-primary mb-0'>PTE Test can are of two types:</h3>
                                <ul>
                                    <li>PTE Academic Test</li>
                                    <li>PTE General Training</li>
                                </ul>
                                <p className='my-0'>PTE Academic Test and PTE General Training are two types of PTE Tests and consist of Listening, Speaking, Reading and Writing similar to IELTS Test. Even though Listening and Speaking tests are the same, there’s a slight variation on Reading and Writing tests when compared to IELTS Tests..</p>
                                <h3 className='text-primary mb-0'>PTE Preparation Classes available in Kathmandu</h3>
                                <p>If you want to enroll in a PTE Preparation class, you have come to the right place. We offer the best PTE Classes in Kathmandu, Nepal.<br />
                                    <strong>Optima International Education</strong><br />
                                    <a href='tel:9851062144'>9851062144</a> / <a href='9803027908'>9803027908</a><br /> <a href='mailto:riteshbasnet123@gmail.com'>riteshbasnet123@gmail.com</a> <br /> Dillibazar, Kathmandu</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='preparation-section py-5 bg-light'>
                <div className='container'>
                    <div className='text-center'>
                        <h2 className='my-0 text-primary'>Benefits of Preparing PTE from PTE Preparation Center</h2>
                        <h4 className='my-1'>Let's look at the benefits that you can avail from enrolling into our PTE Preparation center.</h4>
                    </div>
                    <div className='d-grid mt-4'>
                        <div className='d-flex'>
                            <div>
                                <h3 className='text-primary mb-0'>Availability of resources</h3>
                                <p className='mt-1'>An institution has a lot of resources with them that can help you to prepare better. Such resources can be guides, pdf, etc. They can help you prepare better and practice for the examination.</p>

                                <h3 className='text-primary mb-0'>Experienced instructors</h3>
                                <p className='mt-1'>To be able to instruct students, one needs to obtain at least band 7 in their PTE test. Learning from somebody who has himself/herself secured good marks can help you broaden your vision towards the test.</p>

                                <h3 className='text-primary mb-0'>Frequent revisions, tests, and guidance</h3>
                                <p className='mt-1'>You cannot know if you are good at something unless you push yourself to the limit. To push yourself, you need to know where your starting point is. For this, you need to attain tests frequently and improve accordingly with guidance. Your PTE instructor will guide you throughout and make sure that you attain better results.</p>

                                <h3 className='text-primary mb-0'>Learning in a group</h3>
                                <p className='mt-1'>Learning with a group of people can teach you a lot of things about things that you did not know. Most of the PTE classes will have people better than you or who are not as good as you are. You can learn from the people who are better than you and you can teach yourself to not make similar mistakes like the people who are not as good as you are, make. <br />You can either join an PTE class or stay at home and prepare for the PTE test using the internet as your main resource.</p>
                            </div>
                        </div>
                        <div>
                            <LazyLoadImage
                                src='images/welcome.jpg'
                                alt='img'
                                effect='blur'
                            ></LazyLoadImage>

                        </div>
                    </div>
                </div>
            </section>
            <section className='py-5 classes-test-prepartion'>
                <div className='container'>
                    <div className='text-center'>
                        <h2 className='text-primary my-0'>What is the duration of PTE Test?</h2>
                        <p>PTE Test's length is the same as IELTS which is 3 hours.The PTE Tests are taken in three parts. One part includes Reading and other is Listening whereas another part covers Speaking and Writing Test that can last from 10 minutes to half an hour or more. The time frame depends upon the examiner.</p>
                    </div>
                    <div className='d-grid mt-3'>
                        <div>
                            <LazyLoadImage
                                src='images/pte2.jpg'
                                alt='img'
                                effect='blur'
                            ></LazyLoadImage>

                        </div>
                        <div className='d-flex'>
                            <div>
                                <h3 className='mb-0 text-primary'>Listening</h3>
                                <p className='my-0'>Listening test can gpo from 45 minutes to an hour. In Listening Test you will have such tasks like Summarize Spoken Test, Multiple Choice, Multiple Answer, Fill in the Blanks, Highlight the Correct Summary, Multiple Choice, Single Answer, Select the Missing World, Highlight the Incorrect Words and Write from Dictation.</p>

                                <h3 className='mb-0 text-primary'>Reading</h3>
                                <p className='my-0'>Reading section can last from 30 mins to one hour. In this section I will have to accomplish many tasks and questions. Reading and Writing Fill in the blanks, Multiple Choice, Multiple Correct Answers, Re-order Paragraphs, Fill in the Blanks, Multiple Choice, Single Correct Answer are some of the examples.</p>

                                <h3 className='mb-0 text-primary'>Writing</h3>
                                <div>
                                    <p className='my-0'>This portion of the test is divided into two parts each :</p>
                                    <p className='my-0'><strong>150 words</strong></p>
                                    <p className='my-0'>The short portion requires you to describe a diagram or a pie chart or any similar visual chart in about 150 words. The examiner sees how good you clearly can you explain the diagram.</p>
                                    <p className='my-0'><strong>200 words</strong></p>
                                    <p className='my-0'>For the long portion, you are provided a situation and you are required to give your opinion on the situation. The examiner sees how well you can use the English language to describe your views and opinions.</p>
                                </div>

                                <h3 className='mb-0 text-primary'>Speaking</h3>
                                <p className='my-0'>Unlike IELTS, PTE evaluates a student's speaking and writing skills simultaneously. Although the Speaking section is based on communication in an academic learning environment, the writing section's main motive is to examine a student by making them write text through different modes.</p>
                                <p className="my-0">The different writing modes in which you will have to write are: Personal Introduction, Read Aloud, Repeat Sentence, Describe and Image, Re-Tell Lecture, Answer Short Questions, Summarize Written Text as well as Essay. </p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
