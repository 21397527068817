import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Link } from 'react-router-dom';
import ReactWhatsapp from 'react-whatsapp';

// import $ from "jquery";
export const Footer = () => {
    var socialMediaLink = "";
    var countryFlag = [
        {
            src: "images/australia-flag.jpg",
            title: "Australia",
            href: "/australia"
        },
        {
            src: "images/usa-flag.jpg",
            title: "USA",
            href: "/usa"
        },
        {
            src: "images/uk-flag.jpg",
            title: "UK",
            href: "/uk"
        },
        {
            src: "images/canada-flag.jpg",
            title: "Canada",
            href: "/canada"
        },
        // {
        //     src: "images/newzealand-flag.jpg",
        //     title: "New Zealand",
        //      href: ""
        // },
        {
            src: "images/europe-flag.jpg",
            title: "Europe",
            href: "/europe"
        },
        // {
        //     src: "images/japan-flag.jpg",
        //     title: "Japan",
        //     href: ""
        // },
        {
            src: "images/dubai-flag.jpg",
            title: "Dubai",
            href: "/dubai"
        }
    ]
    return (

        <footer className='bg-secondary'>

            <div className="container">
                <div className="topfooter py-5 text-light">
                    <div className="logo text-center">
                        <LazyLoadImage
                            src='images/logo-light.png'
                            effect='blur'
                            alt='Logo'
                        ></LazyLoadImage>
                        {
                            // <img src="images/logo-light.png" alt="" />
                        }
                        <p className='desc'>We are one of the best Educational Consultancy based in Putalisadak, Kathmandu, Nepal and have assisted many international students and shaped their future with studies abroad. If you want to connect to us to clarify your queries regarding abroad studies, Universities and everything else, then feel free to fill the contact form and one of our expert counselors will call you to answer all your worrying questions. Nevertheless, you can call us on 9851062144 / 9803027908 for urgent queries.</p>
                        <div className="contary-flag mt-3">
                            {
                                countryFlag.map((countryFlag, index) => {
                                    return (
                                        <div className='text-center' key={index}>
                                            <a href={countryFlag.href}>
                                                <LazyLoadImage
                                                    src={countryFlag.src}
                                                    alt={countryFlag.title}
                                                    effect="blur"
                                                >
                                                </LazyLoadImage>
                                            </a>
                                            {
                                                // <img src={countryFlag.src} alt="" />
                                            }
                                            <p className='mt-0 mb-0'>{countryFlag.title}</p>
                                        </div>
                                    )
                                })
                            }


                        </div>
                    </div>

                </div>
                <div className='bottomfooter py-5 text-light'>
                    <div>
                        <h3 className='mt-0'>Contact Info</h3>
                        <div>
                            <p className='my-0'><span>Phone</span> : &nbsp;&nbsp; 9851062144 / 9803027908</p>
                            <p className='my-0'><span>Address</span> : &nbsp;&nbsp; Putalisadak, Kathmandu</p>
                            <p className='my-0'><span>Email</span> : &nbsp;&nbsp; riteshbasnet123@gmail.com</p>
                            <p className='my-0'><span>Website</span> : &nbsp;&nbsp; https://optima.edu.np/</p>
                        </div>
                    </div>
                    <div>
                        <h3 className='mt-0'>Our Classes</h3>
                        <div className='foot-class'>
                            <p className='my-0'><Link to="/ielts">IELTS</Link></p>
                            <p className='my-0'><Link to="/pte">PTE</Link></p>
                            <p className='my-0'><Link to="/comming">GRE</Link></p>
                            <p className='my-0'><Link to="/comming">TOEFL</Link></p>
                            <p className='my-0'><Link to="/comming">GMAT</Link></p>
                            <p className='my-0'><Link to="/comming">SAT</Link></p>
                        </div>
                    </div>
                    <div>
                        <h3 className='mt-0'>Social Media</h3>
                        <div className='foot-class'>
                            <p className='my-0'><Link to="" onClick={() => {
                                window.open("https://www.facebook.com/optimaeducon")
                            }} >Facebook</Link></p>
                            <p className='my-0'><Link to="" onClick={() => {
                                window.open("https://www.instagram.com/optima_intl/")
                            }} >Instagram</Link></p>

                            <p className='my-0'><Link to="tel:9851062144" target="_blank">Watsapp : 9851062144 </Link></p>
                            <p className='my-0'><Link to="tel:9803027908" target="_blank">Watsapp: 9803027908 </Link></p>
                            {
                                // $(document).ready(function () {
                                //     console.log("I am Here")
                                // })
                            }
                            {
                                //     <p className='my-0'><Link to="">Linkedin</Link></p>
                                // <p className='my-0'><Link to="">Watsapp</Link></p>
                                // <p className='my-0'><Link to="">Twitter</Link></p>
                            }

                        </div>
                    </div>
                    <div>
                        <h3 className='mt-0'>Quick Link</h3>
                        <div className='foot-class'>
                            <p className='my-0'><Link to="/">Home</Link></p>
                            <p className='my-0'><Link to="/about">About</Link></p>
                            {
                                // <p className='my-0'><Link to="">Study Abroad</Link></p>
                                // <p className='my-0'><Link to="">Test Preparation</Link></p>
                            }
                            <p className='my-0'><Link to="/comming">Scholarship</Link></p>
                            <p className='my-0'><Link to="/contact">Contact</Link></p>

                        </div>
                    </div>
                </div>

            </div>
            <div className='bottomfooter2 py-2 text-light' style={{ background: '#111111' }}>
                <div className="container">
                    <div className='d-grid'>
                        <div><p className='my-0'><small>© Copyright 2022. Optima International Education. All Rights Reserved.</small></p></div>
                        <div><p className='my-0'><small>Design By <Link to="https://www.designerbinod.com/" target="_blank" className='text-light'>Binod Shrestha <span className='text-primary'>(Designerbinod.com)</span></Link></small></p></div>
                    </div>
                </div>
            </div>

            <ReactWhatsapp number="9851062144" message="Hello" className="Watsapp" >
                <img src='images/whatsapp.svg' />
            </ReactWhatsapp>
        </footer>
    )
}
