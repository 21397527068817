import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
export const UkCountry = () => {
    window.scrollTo(0, 0);
    return (
        <div>
            <section className='innerslider'>
                <LazyLoadImage
                    src='images/uk-slider.jpg'
                    alt='img'
                    placeholderSrc='images/uk-slider-thumb.jpg'
                    effect='blur'
                ></LazyLoadImage>
                {
                    // <img src='images/uk-slider.jpg'></img>
                }
                <div className='caption bg-primary text-light'>
                    <h2 className='mt-0 mb-1'>United Kingdom </h2>
                    <p>The UK is renowned as one of the world's best destinations for higher education. All the Universities of the UK have excellent academic excellence and quality in their education system. As of 2020, there were 131 World-class Universities. Among these Universities, 4 of them fall under the roof of World’s Top 6 Universities. Furthermore, the country offers comprehensive support for all international students. Likewise, the country has a history of teaching world’s greatest leaders, thinkers, scientists and  names.</p>
                </div>
            </section>
            <section className='education-system-section py-5 bg-light'>
                <div className="container">
                    <div className='d-grid'>
                        <div>
                            <h2 className='mb-0 mt-0'>The Education System</h2>
                            <h1 className='mb-0 mt-0 text-primary'>Study In United Kingdom from Nepal</h1>
                            <p><strong>Why Study in Australia from Nepal is a great choice?
                            </strong></p>
                            <ul>
                                <li>Currently, more than 100,000 international students go to the UK yearly for education</li>
                                <li>The UK hosts 131 Universities, 4 of them fall under World's Top 6 Universities</li>
                                <li>Excellent academic excellence and quality in their education</li>
                                <li>Part-time job flexibility for all international students</li>
                                <li>Best destination in the world for higher education</li>
                            </ul>
                            <p>In the UK, you can avail a wide range of study and courses options that have significant global recognition and provide excellent start for a prosperous career. Nevertheless, with a robust Nepali community in the UK, it is very very easy for a Nepali student to adjust in the UK.</p>
                        </div>
                        <div>
                            <LazyLoadImage
                                src='images/education-system1.jpg'
                                alt='img'
                                effect='blur'
                                className='main-img'
                            ></LazyLoadImage>
                            {
                                // <img className='main-img' src="images/education-system1.jpg" alt="" />
                            }
                        </div>
                    </div>
                    <div className="icon-boxes ">
                        <div className="icon-box bg-primary">
                            <img src="images/icon1.png" />
                            <p>Undergrads</p>
                        </div>
                        <div className="icon-box bg-primary">
                            <img src="images/icon2.png" />
                            <p>Postgrads</p>
                        </div>
                        <div className="icon-box bg-primary">
                            <img src="images/icon3.png" />
                            <p>Diploma</p>
                        </div>
                        <div className="icon-box bg-primary">
                            <img src="images/icon4.png" />
                            <p>Foundation</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="visaRequiremnt py-5">
                <div className="container">

                    <div className="d-grid">
                        <div>
                            <LazyLoadImage
                                src='images/usa-visa.jpg'
                                alt='img'
                                effect='blur'
                            ></LazyLoadImage>
                            {
                                // <img src="images/usa-visa.jpg" />
                            }
                        </div>
                        <div>
                            <div className="">
                                <h2 className="text-primary mt-0">Visa requirements to study in UK from Nepal</h2>
                                <p>Scoring 6.5 in IELTS for Postgraduate and scoring 6 in IELTS for Undergraduate studies is a must if you want to be eligible to apply for the UK visa as an international student. Likewise, you will need to score 90 in TOEFL for Postgraduate as well as 80 for Undergraduate Studies. </p>
                                <p>When you pass the Language Test, you can choose between 50,000 courses that Universities based in the UK offer. However, you can only enroll into courses that your education background supports. Plus, if you don’t know where to take your Language Classes then we Optima International Education have your back.</p>
                            </div>
                        </div>

                    </div>
                    <div>
                        <div>
                            <p><strong>Things you will require to apply for a Visa to UK:</strong></p>
                            <ul>
                                <li>Your Valid Passport</li>
                                <li>Fill up Online Student Visa Application form and Visa Fee</li>
                                <li>Attested copies of mark sheets of SEE, +2 and all of all Bachelors Degrees. If you have enrolled into any other miscellaneous courses, submitting it will also be a plus point</li>
                                <li>2 Academic reference letters from professors who have taught you. One reference in case of UF application on the UCAS application form itself</li>
                                <li>If you have work experience then at least one work recommendation from the employer</li>
                                <li>Your CV/Resume</li>
                                <li>No objection certificate from MOE</li>
                                <li>Photocopy of your IELTS/TOEFL/GMAT result</li>
                                <li>Tuberculosis test certificate from an approved clinic IOM</li>
                                <li>Complete Biometric Data Collection from VFS GLOBAL</li>
                                <li>Confirmation of Acceptance for studies (CAS). Also, any original documents referenced by your Tier 4 sponsor on the CAS</li>
                                <li>Others (Certificates/Achievements at the State and National Level and Extra Curricular activities)</li>
                                <li>Income certificate as well as sponsor letter is a must</li>
                                <li>A blank slip, bank statements, student loan or certificate of deposit that clearly shows you have held enough money to cover your courses fees and your monthly living costs for up to 9 months (Must be held for 28 Days in Bank).</li>
                            </ul>
                            <br />
                            {
                                // <a className="btn btn-primary py-1 px-2 mt-2">Know More About USA Student Visa</a>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
