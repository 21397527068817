import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
export const DubaiCountry = () => {
    window.scrollTo(0, 0);
    return (
        <div>
            <section className='innerslider'>
                <LazyLoadImage
                    src='images/dubai-slider.jpg'
                    alt='img'
                    effect='blur'
                ></LazyLoadImage>
                {
                    // <img src='images/dubai-slider.jpg'></img>
                }
                <div className='caption bg-primary text-light'>
                    <h2 className='mt-0 mb-1'>Dubai </h2>
                    <p>Dubai's education system is very comparable to countries as it also boasts the best education system in the world. Similarly, the city has over 60 globally respected international Universities and colleges. Likewise, just like Australia, UK and Canada, Dubai offers part time jobs for international students whilst studying. Dubai is one of the most developed and advanced cities in the world. But the best part is, the population of Nepalese people and Nepalese community is huge in Dubai so, Nepali students won't have any difficulty to settle in Dubai.</p>
                </div>
            </section>
            <section className='education-system-section py-5 bg-light'>
                <div className="container">
                    <div className='d-grid'>
                        <div>
                            <h2 className='mb-0 mt-0'>The Education System</h2>
                            <h1 className='mb-0 mt-0 text-primary'>Study In Dubai from Nepal</h1>
                            <p><strong>Why Study in Australia from Nepal is a great choice?
                            </strong></p>
                            <ul>
                                <li>Forward thinking courses and education programs that can benefit all multi-cultured international students</li>
                                <li>Dubai is ranked as the 2nd safest city in the world</li>
                                <li>Falls under the World’s 3rd richest country in the world as per capita income</li>
                                <li>Qualified students have international recognition of qualification</li>
                                <li>Process of applying for Visa is easy and simpler than that of other countries</li>
                                <li>You can enroll into courses like International Hospitality Management, Aerospace engineering, Civil Engineering/Construction Management as well as Business Administration/MBA.</li>
                            </ul>
                            <p>Some of the most popular Universities in Dabai are University of Wollongong, Heriot Watt university, Middlesex University, American University of Ras Al Khaimah, Emirates Aviation University. All of these Universities provide top-notch education as well as international qualification.</p>
                        </div>
                        <div>
                            <LazyLoadImage
                                src='images/education-system.jpg'
                                alt='img'
                                className='main-img'
                                effect='blur'
                            ></LazyLoadImage>

                        </div>
                    </div>
                    <div className="icon-boxes ">
                        <div className="icon-box bg-primary">
                            <img src="images/icon1.png" />
                            <p>Undergrads</p>
                        </div>
                        <div className="icon-box bg-primary">
                            <img src="images/icon2.png" />
                            <p>Postgrads</p>
                        </div>
                        <div className="icon-box bg-primary">
                            <img src="images/icon3.png" />
                            <p>Diploma</p>
                        </div>
                        <div className="icon-box bg-primary">
                            <img src="images/icon4.png" />
                            <p>Foundation</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="visaRequiremnt py-5">
                <div className="container">
                    <div className="d-grid">
                        <div>
                            <div className="text-left">
                                <h2 className="text-primary mt-0">Visa requirements to study in Dubai from Nepal</h2>
                                <p>There are lots of documents and papers that you will require to obtain a Visa to Dubai.If you are confused regarding any documents then you can talk with our counselors at any time you prefer. </p>
                                <p>Our counselors are well experienced and have helped many international students choose the right course and University that have benefited their career handsomely. Therefore, if you want to shape your future into a prosperous one then come to us and we will walk throughout your journey with you. Here are some of the documents that you will need to have.</p>
                            </div>
                        </div>
                        <div>
                            <LazyLoadImage
                                src='images/visa-img.jpg'
                                alt='img'
                                effect='blur'
                            ></LazyLoadImage>

                        </div>
                    </div>
                    <div>
                        <div>
                            <p><strong>Things you will need to acquire Visa for Dubai:</strong></p>
                            <ul>
                                <li>You will need a Valid Passport</li>
                                <li>Get four Passport copies</li>
                                <li>Have twelve recent passport-sized photographs with white background</li>
                                <li>Receive an Admission offer letter from UAE University</li>
                                <li>Ready your bank statements for the verification of funds</li>
                                <li>Sign a tenancy agreement. If a student does not have any, he must get a letter from the university if he will be staying in the University Accomodation</li>
                                <li>Copy of tuition fee receipt for the current year of the study programm</li>
                                <li>If you are a Postgraduate, then you will need to show proof of their undergraduate degree</li>
                                <li>Once you are in  Dubai, you will have to undergo a medical test from an authorized medical center. The test generally includes a medical and chest X ray for HIV, Hepatitis C, Hepatitis B, Leprosy, Syphilis and Tuberculosis. (If any of these test results are positive, then you will be deported except for Syphilis because it can be treated.)</li>
                            </ul>

                            <p><strong>Visa Process of Dubai:</strong></p>
                            <ul>
                                <li>A student will have to fill the Visa application form and attach a passport sized photograph to it</li>
                                <li>You will have to make sure that the attached photograph meets the required specifications</li>
                                <li>Just complete the application by attaching the required documents as per the checklist</li>
                                <li>You will have to submit your application at the Dubai application Center</li>
                                <li>After Visa approval, collect Visa Copy from the application center</li>
                                <li>Pay tuition fee and receive your eCoE</li>
                                <li>Pay tuition fee and receive your eCoE</li>
                                <li>Collect visa grant letter</li>
                            </ul>
                            <br />
                            {
                                //<a className="btn btn-primary py-1 px-2 mt-2">Know More About Dubai Visa</a>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
