import React from 'react'
import { Contact } from '../Component/Contact';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
export const ContactPage = () => {
    window.scrollTo(0, 0);
    return (
        <div>
            <section className='bg-primary text-light py-5 text-center'>
                <h1 className='my-0'>Contact</h1>
            </section>
            <section className='py-5 bg-light text-left contact-page-section1'>
                <div className='container'>
                    <div className='d-grid'>
                        <div className='d-flex'>
                            <div>
                                <h2 className='text-primary mt-0 mb-0'>Our Contact Details</h2>
                                <p className='mt-0'>We are one of the best Educational Consultancy based in Putalisadak, Kathmandu, Nepal and have assisted many international students and shaped their future with studies abroad. If you want to connect to us to clarify your queries regarding abroad studies, Universities and everything else, then feel free to fill the contact form and one of our expert counselors will call you to answer all your worrying questions. Nevertheless, you can call us on 9851062144 / 9803027908 for urgent queries.</p>

                                <div>
                                    <p className='my-0'><span>Phone</span> &nbsp;&nbsp;: &nbsp;&nbsp; <a href='tel:9851062144'><strong>9851062144</strong></a> / <a href='tel:9803027908'><strong>9803027908</strong></a></p>
                                    <p className='my-0'><span>Address</span> &nbsp;&nbsp;: &nbsp;&nbsp; Putalisadak, Kathmandu</p>
                                    <p className='my-0'><span>Email</span> &nbsp;&nbsp;: &nbsp;&nbsp; <a href='mailto:riteshbasnet123@gmail.com'><strong>riteshbasnet123@gmail.com</strong> </a> </p>
                                    <p className='my-0'><span>Website</span> &nbsp;&nbsp;: &nbsp;&nbsp; optima.edu.np/</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <LazyLoadImage
                                src='images/contact.jpg'
                                alt='img'
                                effect='blur'
                            ></LazyLoadImage>

                        </div>
                    </div>
                </div>
            </section>
            <section>
                <Contact></Contact>
            </section>
            <section className='map-section mb-5'>
                <div className='container'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3532.4562055909214!2d85.32047061506195!3d27.703197382793622!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19a64b5f13e1%3A0x28b2d0eacda46b98!2sPutalisadak%2C%20Kathmandu%2044600%2C%20Nepal!5e0!3m2!1sen!2sus!4v1641105882537!5m2!1sen!2sus" width="100%" height="650" allowfullscreen="" loading="lazy" ></iframe>
                </div>
            </section>

        </div>
    )
}
