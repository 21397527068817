import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
export const IeltsClasses = () => {
    window.scrollTo(0, 0);
    return (
        <div>
            <section className='bg-primary text-light py-5 text-center'>
                <h1 className='my-0'>IELTS</h1>
                <h3 className='my-0'>Get Higher Band Scores In IELTS</h3>
            </section>
            <section className='py-5 bg-light text-center'>
                <div className='container'>
                    <h2 className='text-primary mt-0'>What IS IELTS</h2>
                    <p>The International English Language Test System (IELTS) established in 1989 is an standardized and widely recognized English language test for study, migration and work. The main motive of this test is to evaluate the English language proficiency of non-native English speakers such as we Nepalese people.</p>
                    <p>IELTS is necessary for international students if they want to apply for study in countries like Australia, Canada, New Zealand, the UK as well as the USA. In IELTS, students are checked with 4 major parts of any language: Speaking, Reading, Writing and Listening. A student should pass on all these and their whole test is graded on a scale of Score from 1-9. The higher you score in IELTS, it reflects that better understanding of English Language you have when it comes to communicating. However, each country or each individual University has their specific IELTS score requirements that a student will have to excel to be eligible to apply for study or work.</p>
                </div>
            </section>
            <section className='py-5 class-structure'>
                <div className='container'>
                    <h2 className='text-primary text-center'>The IELTS test structure</h2>
                    <div className='d-grid'>
                        <div>
                            <LazyLoadImage
                                src='images/ielts1.jpg'
                                alt='img'
                                effect='blur'
                            ></LazyLoadImage>

                        </div>
                        <div className='d-flex'>
                            <div>
                                <h3 className='text-primary mb-0'>How many types of IELTS tests are there?</h3>
                                <p className='my-0'>The IELTS test is mainly of two types: Academic and General Training. The Test consists of Listening, Speaking, Reading and Writing.</p>
                                <p className='my-0'>In both tests Listening & speaking are the same whereas there is a slight variation on Reading and Writing for Academic and General Training IELTS Test according to the 2018 Exam pattern.</p>
                                <p><strong>There are two types of IELTS Test namely:</strong></p>
                                <ul>
                                    <li>IELTS Academic</li>
                                    <li>IELTS General Training</li>
                                </ul>
                                <p>As we all know, the IELTS test consists of two different tests; IELTS Academic and IELTS General Training. Both tests include Listening, Speaking, Reading as well as Writing. </p>
                                <p>As per 2018 Exam pattern, although both tests have similar Listening and Speaking tests, there’s a slight difference between Reading and Writing tests on each test.</p>
                                <h3 className='text-primary mb-0'>IELTS Preparation Class:</h3>
                                <p>If you want to study abroad and are planning to enroll in the IELTS Test then you have come into the right place. We offer the best IELTS Class in Kathmandu, Nepal..<br />
                                    <strong>Optima International Education</strong><br />
                                    9851062144/9803027908<br />
                                    riteshbasnet123@gmail.com <br />
                                    Dillibazar, Kathmandu</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='preparation-section py-5 bg-light'>
                <div className='container'>
                    <div className='text-center'>
                        <h2 className='my-0 text-primary'>Benefits of Preparing IELTS from IELTS preparation center</h2>
                        <h4 className='my-1'>Here are some of the benefits that you will avail after enrolling into our IELTS Classes:</h4>
                    </div>
                    <div className='d-grid mt-4'>
                        <div className='d-flex'>
                            <div>
                                <h3 className='text-primary mb-0'>Availability of resources</h3>
                                <p className='mt-1'>We have a huge host of IELTS resources that a student can benefit from. Optima INternational Education will provide you with all these resources so that you can learn and practise better. Such resources we offer are; IELTS Books, IELTS PDFs, IELTS Guides, IELTS Sample Questions and overall other kinds of IELTS Study materials. Therefore, your performance will enhance and you will surely pass the IELTS tests guaranteed.</p>

                                <h3 className='text-primary mb-0'>Experienced instructors</h3>
                                <p className='mt-1'>Not just for an international student but for all students, having experienced instructors is a must. An experienced instructor can be your sole guardian throughout the journey in your IELTS road and your way to studying abroad. So, we have handpicked experienced instructors that specialize in IELTS and can help you all the way until you reach your goal. Therefore, with all the other benefits, our experienced instructors are a bonus.</p>

                                <h3 className='text-primary mb-0'>Frequent revisions, tests, and guidance</h3>
                                <p className='mt-1'>Studying hard and mugging the whole session isn’t ideal for students. The knowledge gained with these methods doesn’t last longer and you can forget all things in just a snap or when under pressure. The solution to it is proper revision in a frequent and timely manner. Plus, having tests on a regular basis and being corrected with proper guidance can upheave your performance and knowledge drastically. And that’s what we just offer for all students.</p>

                                <h3 className='text-primary mb-0'>Learning in a group</h3>
                                <p className='mt-1'>Have you ever sat in a classroom with just a few students? If you ever have then you know it’s not productive at all. And if you have never sat in such classrooms then we hope you never will. The more brains in the classroom, the better for everyone to share knowledge and brain boost each other. Our classrooms are always arranged in groups so you have more companions to be with and learn together. This is beneficial for your surroundings as well as for proper interaction in class. </p>
                            </div>
                        </div>
                        <div>
                            <LazyLoadImage
                                src='images/welcome.jpg'
                                alt='img'
                                effect='blur'
                            ></LazyLoadImage>

                        </div>
                    </div>
                </div>
            </section>
            <section className='py-5 classes-test-prepartion'>
                <div className='container'>
                    <div className='text-center'>
                        <h2 className='text-primary my-0'>What is the duration of IELTS Test?</h2>
                        <p>Normally, the IELTS exam lasts up to 3 hours. Tests of Listening, Reading, and Writing. However, Speaking tests are taken separately but on the same day as other tests. Speaking Tests can last from 10 minutes to more than half an hour and completely depends upon the test conductor.</p>
                    </div>
                    <div className='d-grid mt-3'>
                        <div>
                            <LazyLoadImage
                                src='images/ielts2.jpg'
                                alt='img'
                                effect='blur'
                            ></LazyLoadImage>

                        </div>
                        <div className='d-flex'>
                            <div>
                                <h3 className='mb-0 text-primary'>Listening</h3>
                                <p className='my-0'>During the Listening Test you will be given a sample piece of text and you will have to attend 40 questions related to the very text. You will be given 1 hour to complete the whole test. Nevertheless, the Listening Test is the same for both the IELTS Academic TEst as well as IELTS General Training Test.</p>

                                <h3 className='mb-0 text-primary'>Reading</h3>
                                <p className='my-0'>In the Reading Test you will be given 3 pieces of text to read. The time for each piece of text to read is 20 minutes. In total, the Reading Test lasts for exactly 1 hour. Each piece of text has increased difficulty and has over 40 questions to answer. </p>

                                <h3 className='mb-0 text-primary'>Writing</h3>
                                <div>
                                    <p className='my-0'>The Writing test is divided into two parts each:</p>
                                    <p className='my-0'><strong>150 words</strong></p>
                                    <p className='my-0'>In this part, a student is given a diagram, pie chart or a diagram of any type which the student will have to explain in between 0-150 words. The main motive of this part is to check a student’s ability to describe their opinions and view in English writing.</p>
                                    <p className='my-0'><strong>200 words</strong></p>
                                    <p className='my-0'>Thispart is longer than the other one. In this part, a student is given a situation that they will have to describe as per their views and opinions. The motive of this part is also same as the other one but however a student is required to write more and elaborate their views in their writing.</p>
                                </div>

                                <h3 className='mb-0 text-primary'>Speaking</h3>
                                <p className='my-0'>In the IELTS test, speaking tests are taken separately. Normally, Speaking Tests latest from 10 minutes to 30 mins. The time basically depends on the examiner. Examiners can take your speaking test until they can see your fluency in English and determine it. The better and fluent you understand and speak English, the shorter the time will get and vice versa.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
