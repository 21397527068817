import React from 'react'
import { Link } from 'react-router-dom'

const Facebook = () => {
    var socialMediaLink;
    return (
        <div>

            {
                <Link to={window.location = "https://www.facebook.com/optimaeducon"}></Link>
            }
        </div>
    )
}

export default Facebook