
import './App.scss';
import React, { Suspense, lazy } from 'react';
// import { Component } from "./Component/index";
// import { Theme } from "./Component/theme";
import { Header } from './Component/Header'
// const Header = React.lazy(() => import('./Component/Example'))
import { Footer } from './Component/footer';
import { ScrollTop } from './Component/ScrollTop';
// import MessengerCustomerChat from 'react-messenger-customer-chat';

import { BrowserRouter as Router } from 'react-router-dom';
import AnimatedRoute from './Component/AnimatedRoute';



function App() {
  window.scrollTo(0, 0);

  return (
    <div className="App">

      {
        /*
          <div>
        <h1>Hello World</h1>
        <Suspense fallback={<div>Loading...</div>}>
          <Header></Header>
        </Suspense>

      </div>
        */
      }

      <Router>
        <Header></Header>
        <AnimatedRoute></AnimatedRoute>

        <Footer></Footer>
      </Router>
      <ScrollTop></ScrollTop>


    </div>
  );
}

export default App;
