import React, { useState } from 'react'
import { SliderData } from './SliderData'
import { BiArrowBack } from "react-icons/bi";
import { Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
export const Slider = ({ slides }) => {
    const [current, setCurrent] = useState(0);
    const length = slides.length;

    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1)
        console.log(current)
    }
    const prevSlide = () => {
        setCurrent(current === 0 ? length - 1 : current - 1)
        console.log(current)
    }
    if (!Array.isArray(slides) || slides.length <= 0) {
        return null
    }
    return (
        <section className="slider">
            <BiArrowBack className="slider-arrow next" onClick={nextSlide}></BiArrowBack>
            <BiArrowBack className="slider-arrow prev" onClick={prevSlide}></BiArrowBack>
            {
                SliderData.map((slide, index) => {
                    return (
                        <div className={index === current ? 'slide active' : 'slide'} key={index}>
                            {index === current && (
                                <div>
                                    <div className="slider-caption py-4 px-4 text-light">
                                        <h2 className="my-0">{slide.h2}</h2>
                                        <h1 className="my-0">{slide.h1}</h1>
                                        <p>{slide.desc}</p>
                                        <Link to={slide.link} className="no-bg-btn px-1"> Read More <img src="images/long-arrow.svg" /></Link>
                                    </div>
                                    <LazyLoadImage
                                        alt={slide.h1}
                                        effect="blur"
                                        src={slide.image}
                                        // height="300px"
                                        width="100%"
                                        placeholderSrc={slide.thumbImg}
                                    >

                                    </LazyLoadImage>
                                    {
                                        // <img src={slide.image} className="slider-img" alt="img" key={index} />
                                    }
                                    {
                                        /**
                                        <LazyLoadImage
                                        alt={slide.h1}
                                        effect="blur"
                                        src={slide.flag}
                                        height="300px"
                                        width="100%"
                                        placeholderSrc={slide.thumbImg}
                                        className="img-person"
                                        >

                                        </LazyLoadImage>
                                         */
                                    }

                                </div>
                            )}
                        </div>

                    )
                })
            }
        </section>
    )
}
