import React from 'react'

export const TofelClasses = () => {
    return (
        <div>
            <section className='bg-primary text-light py-5 text-center'>
                <h1 className='my-0'>TOFEL</h1>
                <h3 className='my-0'>Get Higher Band Scores In TOFEL</h3>
            </section>
            <section className='py-5 bg-light text-center'>
                <div className='container'>
                    <h2 className='text-primary mt-0'>What IS Tofel</h2>
                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.</p>
                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.</p>
                </div>
            </section>
            <section className='py-5 class-structure'>
                <div className='container'>
                    <h2 className='text-primary text-center'>The TOFEL test structure</h2>
                    <div className='d-grid'>
                        <div>
                            <img src="images/tofel.jpg"></img>
                        </div>
                        <div className='d-flex'>
                            <div>
                                <h3 className='text-primary mb-0'>How many types of TOFEL tests are there?</h3>
                                <p><strong>There are two types of TOFEL Test namely:</strong></p>
                                <ul>
                                    <li>TOFEL Academic</li>
                                    <li>TOFEL General Training</li>
                                </ul>
                                <p className='my-0'>TOFEL test is mainly of two types: Academic and General Training. The Test consists of Listening, Speaking, Reading and Writing.</p>
                                <p className='my-0'>In both tests Listening & speaking are same whereas there is a slight variation on Reading and Writing for Academic and General Training TOFEL Test according to 2018 Exam pattern.</p>
                                <h3 className='text-primary mb-0'>TOFEL Preparation Classes available in Kathmandu:</h3>
                                <p>If you are planning to take TOFEL Test then do Visit us we have a special offer for you.<br />
                                    <strong>Optima International Education</strong><br />
                                    Dillibazar, Kathmandu</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='preparation-section py-5 bg-light'>
                <div className='container'>
                    <div className='text-center'>
                        <h2 className='my-0 text-primary'>Benefits of Preparing TOFEL from TOFEL preparation center</h2>
                        <h4 className='my-1'>Here are some benefits of preparing TOFEL from TOFEL preparation centers:</h4>
                    </div>
                    <div className='d-grid mt-4'>
                        <div className='d-flex'>
                            <div>
                                <h3 className='text-primary mb-0'>Availability of resources</h3>
                                <p className='mt-1'>An institution has a lot of resources with them that can help you to prepare better. Such resources can be guides, pdf, etc. They can help you prepare better and practice for the examination.</p>

                                <h3 className='text-primary mb-0'>Experienced instructors</h3>
                                <p className='mt-1'>To be able to instruct students, one needs to obtain at least band 7 in their TOFEL test. Learning from somebody who has himself/herself secured good marks can help you broaden your vision towards the test.</p>

                                <h3 className='text-primary mb-0'>Frequent revisions, tests, and guidance</h3>
                                <p className='mt-1'>You cannot know if you are good at something unless you push yourself to the limit. To push yourself, you need to know where your starting point is. For this, you need to attain tests frequently and improve accordingly with guidance. Your TOFEL instructor will guide you throughout and make sure that you attain better results.</p>

                                <h3 className='text-primary mb-0'>Learning in a group</h3>
                                <p className='mt-1'>Learning with a group of people can teach you a lot of things about things that you did not know. Most of the TOFEL classes will have people better than you or who are not as good as you are. You can learn from the people who are better than you and you can teach yourself to not make similar mistakes like the people who are not as good as you are, make. <br />You can either join an TOFEL class or stay at home and prepare for the TOFEL test using the internet as your main resource.</p>
                            </div>
                        </div>
                        <div>
                            <img src="images/welcome.jpg"></img>
                        </div>
                    </div>
                </div>
            </section>
            <section className='py-5 classes-test-prepartion'>
                <div className='container'>
                    <div className='text-center'>
                        <h2 className='text-primary my-0'>What is the duration of TOFEL Test?</h2>
                        <p>TOFEL test lasts for 3 hours which includes listening, reading and writing. Speaking test is taken separately which may last for 10 minutes to half an hour depending on the test conductor.</p>
                    </div>
                    <div className='d-grid mt-3'>
                        <div>
                            <img src="images/tofel1.jpg"></img>
                        </div>
                        <div className='d-flex'>
                            <div>
                                <h3 className='mb-0 text-primary'>Listening</h3>
                                <p className='my-0'>In this test, you have to listen to a sample piece of text that is played to you and you have to attend 40 questions related to the text. You have 1 hour to complete the whole test.</p>

                                <h3 className='mb-0 text-primary'>Reading</h3>
                                <p className='my-0'>This portion of the test requires you to read 3 pieces which are provided to you. Each piece is allowed 20 minutes each.</p>

                                <h3 className='mb-0 text-primary'>Writing</h3>
                                <div>
                                    <p className='my-0'>This portion of the test is divided into two parts each :</p>
                                    <p className='my-0'><strong>150 words</strong></p>
                                    <p className='my-0'>The short portion requires you to describe a diagram or a pie chart or any similar visual chart in about 150 words. The examiner sees how good you clearly can you explain the diagram.</p>
                                    <p className='my-0'><strong>200 words</strong></p>
                                    <p className='my-0'>For the long portion, you are provided a situation and you are required to give your opinion on the situation. The examiner sees how well you can use the English language to describe your views and opinions.</p>
                                </div>

                                <h3 className='mb-0 text-primary'>Speaking</h3>
                                <p className='my-0'>Speaking test is taken separately which may last for 10 minutes to half an hour depending on the test conductor. The examiner sees how fluent your English is and if you can/cannot communicate in English with ease.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
