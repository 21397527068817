import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Link } from 'react-router-dom';
export const WelcomeAbout = ({ title, readmore }) => {
    return (
        <section className="bg-light py-5 content-box-2">
            <div className="container">
                <div className="d-flex">
                    <div className="content-icon-box">
                        <div>
                            <div className="">
                                <h1>
                                    <span>{title}</span>
                                    Optima International Education
                                </h1>
                                <div className="content-line-box">
                                    <p className="mb-4">Optima International Education helps every Nepali student connect to the right study opportunities abroad. Our comprehensive experience and knowledge in the international education industry simply makes following your dream to study abroad easier and safe. Furthermore, our global footprint expands over 8 countries. Our Education Counselors are all ex international students thereby they can suggest and advise you on every step of your journey from the start.</p>
                                    {readmore ? <Link to='/about' className="btn btn-primary icon-right">Read More <img src="images/icon.svg" /></Link> : ""}

                                </div>
                            </div>
                        </div>
                        <div className="py-0 px-0 bg-primary  icon-boxs">
                            <div className="icon-box">
                                <img src="images/icon1.png" />
                                <p className="mb-0">Undergrads</p>
                            </div>
                            <div className="icon-box">
                                <img src="images/icon2.png" />
                                <p className="mb-0">Postgrads</p>
                            </div>
                            <div className="icon-box">
                                <img src="images/icon3.png" />
                                <p className="mb-0">Diploma</p>
                            </div>
                            <div className="icon-box">
                                <img src="images/icon4.png" />
                                <p className="mb-0">Foundation</p>
                            </div>
                        </div>
                    </div>

                    <div>
                        {
                            /*<img src="images/welcome.jpg" className="main" alt="" /> */
                        }
                        <LazyLoadImage
                            alt="About"
                            effect="blur"
                            src="images/welcome.jpg"
                            className='main'
                        // height="300px"
                        // width="100%"
                        // placeholderSrc={slide.thumbImg}
                        ></LazyLoadImage>
                    </div>
                </div>
            </div>
        </section>
    )
}
