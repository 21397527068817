import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { FacebookEmbed } from 'react-social-media-embed';
export const Universities = () => {
    var setting = {
        centerMode: true,
        centerPadding: '0',
        slidesToShow: 3,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    arrows: false,
                    centerMode: true,
                    centerPadding: '0',
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    arrows: false,
                    centerMode: true,
                    centerPadding: '0',
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    arrows: false,
                    centerMode: true,
                    centerPadding: '0',
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    centerMode: true,
                    centerPadding: '0',
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    arrows: false,
                    centerMode: true,
                    centerPadding: '0',
                    slidesToShow: 1
                }
            }
        ]
    }
    const uniLogo = [
        {
            sno: 1,
            src: "images/uni-logo1.png"
        },
        {
            sno: 2,
            src: "images/uni-logo2.png"
        },
        {
            sno: 3,
            src: "images/uni-logo3.png"
        },
        {
            sno: 4,
            src: "images/uni-logo1.png"
        },
        {
            sno: 5,
            src: "images/uni-logo2.png"
        },
        {
            sno: 6,
            src: "images/uni-logo3.png"
        },
    ]


    return (

        <section className='universities-section'>
            <LazyLoadImage
                alt="University"
                className='bg-img'
                src='images/university-bg.jpg'
                effect='blur'
            ></LazyLoadImage>
            {
                // <img src="images/university-bg.jpg" className='bg-img' alt="" />
            }
            {
                // <div className='university-logo'>
                // <Slider {...setting}>

                //     {
                //         uniLogo.map((uniLogo) => {
                //             return (
                //                 <div>
                //                     <LazyLoadImage
                //                         alt="University"
                //                         src={uniLogo.src}
                //                         effect='blur'
                //                         key={uniLogo.sno}
                //                     ></LazyLoadImage>

                //                 </div>
                //             )
                //         })
                //     }

                // </Slider>
                // </div>
            }


        </section>
    )
}
