import React, { useRef, useState } from 'react';

import emailjs from '@emailjs/browser';

export const Contact = (event) => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_s23mq3y', 'template_iv91wtr', form.current, 'zgDwB40VTJ7Kjuyn9')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    };

    return (
        <div className='py-5 px-5 contact-form-section'>
            <div className=''>
                <h1 className='text-primary mt-0'>Contact Us</h1>
                <form method='get' onSubmit={sendEmail} ref={form}>
                    <div>
                        <input type="text" className='input-text' name='firstName' placeholder='First Name' />
                        <input type="text" className='input-text' name='email' placeholder='Your Email Address' />


                        <input type="text" className='input-text' name='yourPhone' placeholder='Your Phone' />

                    </div>

                    <h4>Additional Information</h4>

                    <div>
                        <select className='input-text' name='classes'>
                            <option >Our Classes</option>
                            <option value="IELTS">IELTS</option>
                            <option value="PTE">PTE</option>
                        </select>

                        <select className='input-text' name='destination' >
                            <option >Destination</option>
                            <option value="Australia">Australia</option>
                            <option value="USA">USA</option>
                            <option value="Canada">Canada</option>
                            <option value="UK">UK</option>
                            <option value="Japan">Japan</option>
                            <option value="Dubai">DUbai</option>
                        </select>
                    </div>
                    <textarea className='input-text' rows={10} name='details' type='text'
                        defaultValue={event.symptoms}
                        required id='AddEventForm__symptoms'>
                        Message
                    </textarea>
                    <button type='submit' className='btn btn-primary mt-2'>Send </button>

                </form>
            </div>
        </div>
    )
}
