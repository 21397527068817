import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
export const PageUsaCountryList = () => {
    window.scrollTo(0, 0);
    return (
        <div>
            <section className='innerslider'>
                <LazyLoadImage
                    src='images/usa-slider.jpg'
                    alt='img'
                    effect='blur'
                    placeholderSrc='images/usa-slider-thumb.jpg'
                ></LazyLoadImage>
                {
                    // <img src='images/usa-slider.jpg'></img>
                }
                <div className='caption bg-primary text-light'>
                    <h2 className='mt-0 mb-1'>USA</h2>
                    <p>As we all know, the USA is crowned as the Land of Opportunities. Most of the world’s biggest names are based in the USA. One of the main reasons is its education system that suits and takes out the most of a student. Currently, the country hosts more than 10,00,000 international students and welcomes international students every year with open arms. However,  career opportunities offered to students after fulfillment of their education is what excites students from all over the world. Hence, the USA is one of the best places for an international student.</p>
                </div>
            </section>
            <section className='education-system-section py-5 bg-light'>
                <div className="container">
                    <div className='d-grid'>
                        <div>
                            <h2 className='mb-0 mt-0'>The Education System</h2>
                            <h1 className='mb-0 mt-0 text-primary'>Study In United States of America from Nepal</h1>
                            <p><strong>Why Study in Australia from Nepal is a great choice?
                            </strong></p>
                            <ul>
                                <li>The USA hosts some of the finest universities and has high academic standards.(33 of the top 100 Universities in World are in the USA.) </li>
                                <li>Flexible education system for all international students</li>
                                <li>Excellent support system for international students</li>
                                <li>Great Cultural diversity that makes everyone equal</li>
                                <li>Lively and vibrant campus life with great services and features</li>
                                <li>Yearly scholarship opportunities for international students across the globe</li>
                            </ul>
                            <p>Due to such great reasons, the USA has become the best destination for international students.</p>
                        </div>
                        <div>
                            <LazyLoadImage
                                src='images/usa-education-system.jpg'
                                className='main-img'
                                alt='img'
                                effect='blur'
                            ></LazyLoadImage>
                            {
                                // <img className='main-img' src="images/usa-education-system.jpg" alt="" />
                            }
                        </div>
                    </div>
                    <div className="icon-boxes ">
                        <div className="icon-box bg-primary">
                            <img src="images/icon1.png" />
                            <p>Undergrads</p>
                        </div>
                        <div className="icon-box bg-primary">
                            <img src="images/icon2.png" />
                            <p>Postgrads</p>
                        </div>
                        <div className="icon-box bg-primary">
                            <img src="images/icon3.png" />
                            <p>Diploma</p>
                        </div>
                        <div className="icon-box bg-primary">
                            <img src="images/icon4.png" />
                            <p>Foundation</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="visaRequiremnt py-5">
                <div className="container">
                    <div className="text-center">
                        <h2 className="text-primary mt-0">Visa requirements to study in USA from Nepal</h2>
                        <p>Like all countries, to study in the USA you will need to have a student VISA.However, the Government of USA categorizes its VISA into two categories; Category ‘F’ USA Student VISA is for students who attend school, college, University or other academic institutions including language Program. Another one of Category 'M' USA Student VISA is for vocational and non-academic programs.</p>
                    </div>
                    <div className="d-grid">
                        <div>
                            <LazyLoadImage
                                src='images/usa-visa.jpg'
                                // className='main-img'
                                alt='img'
                                effect='blur'
                            ></LazyLoadImage>
                            {
                                // <img src="images/usa-visa.jpg" />
                            }
                        </div>
                        <div>
                            <div>
                                <p><strong>You will need to do these things to get a Visa for USA:</strong></p>
                                <ul>
                                    <li>Contact Optima International Education and discuss everything with expert counselors</li>
                                    <li>With us you can apply to Universities and Institutes</li>
                                    <li>Enroll into our IELTS Classes or any other accepted English Test</li>
                                    <li>Receive an i20 document</li>
                                    <li>Fill DS 160 form and book you interview for Visa</li>
                                    <li>Attend the Visa Interview and collect your passport</li>
                                </ul>
                                <br />
                                <p>Join your hands with us and all these steps will be a cakewalk for you with our help. Together we will fulfill your dream of studying in the USA.</p>
                                <br />
                                {
                                    //<a className="btn btn-primary py-1 px-2 mt-2">Know More About USA Student Visa</a>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
