import React from 'react'
import { WelcomeAbout } from '../Component/WelcomeAbout';

export const About = () => {
    window.scrollTo(0, 0);
    return (
        <div>
            <section className='bg-primary text-light py-5 text-center'>
                <h1 className='my-0'>About</h1>
            </section>
            <WelcomeAbout title="About" readmore={false}></WelcomeAbout>
            <section className='py-5 text-center'>
                <div className='container'>

                    <h2 className='text-primary mt-0'>Why We Are</h2>
                    <p>We Optima International Education are Nepal's one of the best international Education consultancy based in Putalisadak, Kathmandu. Furthermore, we have partnered with some of the best renowned and famous educational institutes across 8 different countries that welcome international students from across the globe. Plus, we have highly experienced and expert Counselors that have already passed the phase of being an international student. Therefore, they can provide you with the best advice.</p>
                    <p>If you are aspiring to study overseas then we can be of great help to you in every step. We can help you choose the right institution from hundreds of top universities around the globe and guide you to enroll in the best courses that will benefit you and your career. Moreover, we will look after and assist in your whole application process while searching for relevant scholarships that you are eligible for. Nonetheless, we are one of Nepal's fastest growing education consultancy and that's for a reason. </p>
                </div>
            </section>
            <section className='pb-5  class-structure'>
                <div className='container'>

                    <div className='d-grid text-center'>
                        <div>
                            <h2 className='text-primary'>Why Choose Us</h2>
                            <p>There are many reasons why you should choose us as you are trying to study abroad. We understand trusting and depending on an educational consultancy is a brave thing currently but we assure you we are here to help you in every way possible. Our qualified team has first-hand experience in international study who have helped hundreds of students achieve their desire of studying overseas. Plus, we just don’t understand your desire but rather we understand your career goals and interests as well. Therefore, we will identify the perfect university that will perfectly suit your needs and help you in fulfilling your career goal.</p>
                        </div>
                        <div>
                            <h2 className='text-primary'>Mission</h2>
                            <p>Our first and foremost mission is to lend you a hand in this wonderful journey of you studying abroad. Also, our mission is to be Nepal’s top International Education Consultancy that every international student can trust and have faith in. This is exactly why we, Optima International Education, work hard every time to help not just one or two but every student that seeks help from us to start their journey. All we seek and work hard for is to see all the students pursuing their career whilst studying abroad that provides proper education, proper healthcare, proper guidance for career growth that will inevitably shape their future.</p>
                        </div>

                    </div>
                </div>
            </section>

        </div>
    )
}
