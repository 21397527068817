import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export const Gallery = () => {
    window.scrollTo(0, 0);

    const ghalleyImg = [
        {
            sno: 1,
            src: "images/gallery/img1.jpg"
        },
        {
            sno: 2,
            src: "images/gallery/img2.jpg"
        },
        {
            sno: 3,
            src: "images/gallery/img3.jpg"
        },
        {
            sno: 4,
            src: "images/gallery/img4.jpg"
        },
        {
            sno: 5,
            src: "images/gallery/img5.jpg"
        },
        {
            sno: 6,
            src: "images/gallery/img6.jpg"
        },
        {
            sno: 7,
            src: "images/gallery/img7.jpg"
        },
        {
            sno: 8,
            src: "images/gallery/img8.jpg"
        },
        {
            sno: 9,
            src: "images/gallery/img9.jpg"
        },
    ]


    return (
        <div>
            <section className='bg-primary text-light py-5 text-center'>
                <h1 className='my-0'>Gallery</h1>
            </section>



            <section className='pb-5 py-5  class-structure'>
                <div className='container'>
                    <div className='d-grid text-center'>
                        {
                            ghalleyImg.map((uniLogo) => {
                                return (
                                    <div>
                                        <LazyLoadImage
                                            alt="University"
                                            className='bg-img'
                                            src={uniLogo.src}
                                            effect='blur'
                                        ></LazyLoadImage>
                                    </div>
                                )
                            })
                        }


                    </div>
                </div>
            </section>

        </div>
    )
}
