import React, { useState } from 'react';

// import Iframe from 'react-iframe'
export const Staff = ({ slides }) => {
    return (
        <div className="">
            <h2 className='mt-0 text-white'>Our Team</h2>
            <div className="teamOuterBox">
                <div>
                    <img src="images/staff/ritesh.jpg" className='w-100' alt="" />
                    <h3 className='text-primary mb-0'>RITESH BASNET</h3>
                    <p className='text-dark mt-0'>CEO/FOUNDER</p>
                </div>
                <div>
                    <img src="images/staff/rakin.jpg" className='w-100' alt="" />
                    <h3 className='text-primary mb-0'>RAKIN BAJRACHARYA</h3>
                    <p className='text-dark mt-0'>BUSINESS DEVELOPMENT OFFICER </p>
                </div>
                <div>
                    <img src="images/staff/pranish.jpg" className='w-100' alt="" />
                    <h3 className='text-primary mb-0'>PRANISH GOPALI</h3>
                    <p className='text-dark mt-0'>BUSINESS DEVELOPMENT OFFICER</p>
                </div>
                <div>
                    <img src="images/staff/richa.jpg" className='w-100' alt="" />
                    <h3 className='text-primary mb-0'>RICHA RAJBHANDARI</h3>
                    <p className='text-dark mt-0'>COUNSELOR/ DOCUMENTATION OFFICER</p>
                </div>
                <div>
                    <img src="images/staff/pratikchya.jpg" className='w-100' alt="" />
                    <h3 className='text-primary mb-0'>PRATIKCHYA DAHAL</h3>
                    <p className='text-dark mt-0'>COUNSELOR</p>
                </div>
                <div>
                    <img src="images/staff/man.jpg" className='w-100' alt="" />
                    <h3 className='text-primary mb-0'>SUNIL DHAKAL</h3>
                    <p className='text-dark mt-0'>IELTS/PTE CERTIFIED INSTRUCTOR</p>
                </div>
            </div>
        </div>

    )

}

