import React from 'react'
import { Link } from 'react-router-dom'

function Instagram() {
    return (
        <div>
            <Link to={window.location = "https://www.instagram.com/optima_intl/"}></Link>

        </div>
    )
}

export default Instagram