import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
export const PageCountryList = () => {

    window.scrollTo(0, 0);

    return (
        <div>
            <section className='innerslider'>
                <LazyLoadImage
                    alt='Australia'
                    src='images/slider2.jpg'
                    effect='blur'
                    placeholderSrc='images/slider2-thumb.jpg'
                ></LazyLoadImage>
                {
                    // <img src='images/slider2.jpg'></img>
                }
                <div className='caption bg-primary text-light'>
                    <h2 className='mt-0 mb-1'>AUSTRALIA</h2>
                    <p>Australia is one of the top-destination for Nepali students who plan to study abroad. Currently, there are more than 1o Lakhs Nepalese students studying in Australia and the number is rising day by day. Nevertheless, it’s all because eight top Universities are based in Australia among top 100 Universities globally which offer more than 22,000 courses. Furthermore, students are offered to work which enables students to fill their pockets that contributes to living an easy life. In this journey Optima International Education can be your guide in hand.</p>
                </div>
            </section>
            <section className='education-system-section py-5 bg-light'>
                <div className="container">
                    <div className='d-grid'>
                        <div>
                            <h2 className='mb-0 mt-0'>The Education System</h2>
                            <h1 className='mb-0 mt-0 text-primary'>Study In Australia from Nepal</h1>
                            <p><strong>Why Study in Australia from Nepal ?</strong></p>
                            <ul>
                                <li>Australia hosts 8 Top Universities that fall under top 100 Universities globally</li>
                                <li>The University System in Australia is much better when compared to Universities of Germany and New Zealand</li>
                                <li>Majority of Universities are based in capital and main cities of Australia</li>
                                <li>More than over AUD. 200,000,000 is spent annually by the Government of Australia for International Scholarships</li>
                                <li>The country has a welcoming environment for all international students.Plus, the country’s ambiance is something very attractive and peaceful</li>
                            </ul>
                            <p>If we keep talking about Australia, then we might not never stop.All of the reasons mentioned above are just some pieces of good things the country offers and there’s a bulk more of points.</p>
                        </div>
                        <div>
                            <LazyLoadImage
                                src='images/education-system.jpg'
                                className='main-img'
                                alt='img'
                                effect='blur'
                            ></LazyLoadImage>
                            {
                                // <img className='main-img' src="images/education-system.jpg" alt="" />
                            }
                        </div>
                    </div>
                    <div className="icon-boxes ">
                        <div className="icon-box bg-primary">
                            <img src="images/icon1.png" />
                            <p>Undergrads</p>
                        </div>
                        <div className="icon-box bg-primary">
                            <img src="images/icon2.png" />
                            <p>Postgrads</p>
                        </div>
                        <div className="icon-box bg-primary">
                            <img src="images/icon3.png" />
                            <p>Diploma</p>
                        </div>
                        <div className="icon-box bg-primary">
                            <img src="images/icon4.png" />
                            <p>Foundation</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="visaRequiremnt py-5">
                <div className="container">

                    <div className="d-grid">
                        <div>
                            <LazyLoadImage
                                src='images/visa-img.jpg'
                                alt='img'
                                effect='blur'
                            ></LazyLoadImage>
                            {
                                // <img src="images/visa-img.jpg" />
                            }
                        </div>
                        <div>

                            <div>
                                <div className="">
                                    <h2 className="text-primary mt-0">Visa Process</h2>
                                    <p>Getting a VISA from Australia might be hard for a student as the country has a stringent VISA system. However, with our guidance and help, the VISA process will be a cakewalk for you. </p>
                                    <p>There are different ways to acquire an Australian VISA but the process depends upon your age, your study background and the study program you are trying to indulge in. Furthermore, the main question is how long will you be living in Australia and other various general requirements. These are all the things that can make a difference.</p>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="d-block">
                        <p><strong>Steps for acquiring Australia's VISA:</strong></p>
                        <ul>
                            <li>Get in touch with Optima International Education and consult with our expert counselors</li>
                            <li>Enroll into our IELTS or PTE Classes</li>
                            <li>Apply to Universities or Institutions of Australia with our help</li>
                        </ul>
                        <p><strong>Documents needed:</strong></p>
                        <ul>
                            <li>Official proof of enrollment</li>
                            <li>Valid Passport</li>
                            <li>Your Genuine Temporary Entrant (GTE) Statement</li>
                            <li>Academic and work experience documents</li>
                            <li>Evidence of your IELTS score</li>
                            <li>Evidence of financial ability such as tuition fees, living expenses, return airfare and so on</li>
                            <li>Details of your health insurance policy (Overseas Student Health Cover)</li>
                            <li>Statement of Purpose and Passport Size Photographs</li>
                        </ul>
                        <p>If you are confused then feel free to call us at 9851062144 / 9803027908. Our team members will be at your service and you can easily consult with our expert counselors to remove all your donuts and worries.</p>
                        <br />
                        {
                            //<a className="btn btn-primary py-1 px-2 mt-2">Know More About Australia Student Visa</a>
                        }
                    </div>
                </div>
            </section>
        </div>
    )
}
