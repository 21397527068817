import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
export const CanadaCountry = () => {
    window.scrollTo(0, 0);
    return (
        <div>
            <section className='innerslider'>
                <LazyLoadImage
                    src='images/canada2.jpg'
                    alt='img'
                    effect='blur'
                    placeholderSrc='images/canada2-thumb.jpg'
                ></LazyLoadImage>
                {
                    // <img src='images/canada2.jpg'></img>
                }
                <div className='caption bg-primary text-light'>
                    <h2 className='mt-0 mb-1'>Canada</h2>
                    <p>Today, there are more than 250,000 international students studying in Canada. The demand of international students to study in Canada isn’t showing signs of slowing but rather is upheaving day by day. The country has established itself as one of the world’s best studying destinations for international students from around the world. Canada is home to many top universities of the World and consist of top cities like Ontario, British Columbia as well as Quebec. Nevertheless, Canada is the world’s second largest country and is filled with places to explore.</p>
                </div>
            </section>
            <section className='education-system-section py-5 bg-light'>
                <div className="container">
                    <div className='d-grid'>
                        <div>
                            <h2 className='mb-0 mt-0'>The Education System</h2>
                            <h1 className='mb-0 mt-0 text-primary'>Study In Canada from Nepal</h1>
                            <p><strong>Why Study in Australia from Nepal is a great choice?
                            </strong></p>
                            <ul>
                                <li>Offers students Health cover </li>
                                <li>Equips top courses like biomedical Engineering, Animal and Veterinary Studies, Construction Management, Law and Legal Studies, Civil Engineering as well as Nutrition and health</li>
                                <li>Students can work up to 20 hours per week and full time during scheduled breaks such as during winter and summer holidays or else during spring break</li>
                                <li>Canada offers a higher standard of living than most other countries</li>
                                <li>Best in class quality of education and highest standards of living that is affordable to international students</li>
                            </ul>
                            <p>Canada has become one of the most desired destinations for international students because of these reasons. It offers students health cover as well as enables students to work for 20 hours per week. Plus, the education level and living standard of Canada is one of the highest in the world</p>
                        </div>
                        <div>
                            <LazyLoadImage
                                src='images/education-system1.jpg'
                                alt='img'
                                className='main-img'
                                effect='blur'
                            ></LazyLoadImage>
                            {
                                // <img className='main-img' src="images/education-system1.jpg" alt="" />
                            }
                        </div>
                    </div>
                    <div className="icon-boxes ">
                        <div className="icon-box bg-primary">
                            <img src="images/icon1.png" />
                            <p>Undergrads</p>
                        </div>
                        <div className="icon-box bg-primary">
                            <img src="images/icon2.png" />
                            <p>Postgrads</p>
                        </div>
                        <div className="icon-box bg-primary">
                            <img src="images/icon3.png" />
                            <p>Diploma</p>
                        </div>
                        <div className="icon-box bg-primary">
                            <img src="images/icon4.png" />
                            <p>Foundation</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="visaRequiremnt py-5">
                <div className="container">
                    <div className="text-center">
                        <h2 className="text-primary mt-0">Visa requirements to study in Canada from Nepal</h2>
                        <p>There's a proper process and required things that you will need to have to apply for a Visa of Canada. However, with us, we will make everything simpler for you.</p>
                        <p>With our and our expert counselors help, we will move forward whilst keeping every minor and small detail in mind which will be beneficial for the process and the chances of you acquiring your Visa to Canada will have high chances. Let’s see a few things that you will need to start the process of acquiring a Visa of Canada.</p>
                    </div>
                    <div className="d-grid">
                        <div>
                            <LazyLoadImage
                                src='images/usa-visa.jpg'
                                alt='img'
                                effect='blur'
                            ></LazyLoadImage>
                            {
                                // <img src="images/usa-visa.jpg" />
                            }
                        </div>
                        <div>
                            <div>
                                <p><strong>Things you will need to apply for Canada Visa:</strong></p>
                                <ul>
                                    <li>Come and talk with our expert counselors at Optima International Education</li>
                                    <li>Apply to Universities in Canada easily through us</li>
                                    <li>Enroll into our English Language Classes (IELTS, SAT, TOEFL or PTE)</li>
                                    <li>Receive institution Letter</li>
                                    <li>Pay Tuition Fees</li>
                                    <li>Give an English Language Test and get a good score</li>
                                    <li>Ready and Lodge Visa Documents</li>
                                    <li>Collect Visa Grant Letters</li>
                                    <li>Pre-Departure services at Optima International Education</li>
                                </ul>
                                <br />
                                {
                                    //<a className="btn btn-primary py-1 px-2 mt-2">Know More About USA Student Visa</a>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
