import React, { useState } from 'react';

// import Iframe from 'react-iframe'
export const SocialMedia = ({ slides }) => {
    return (
        <div className="">
            <h2 className='mt-0 text-white'>Social Media</h2>
            <div >
                <div className='desktop'>
                    <div class="fb-page" data-href="https://www.facebook.com/optimaeducon" data-tabs="timeline" data-width="500" data-height="980" data-small-header="true" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="false"><blockquote cite="https://www.facebook.com/optimaeducon" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/optimaeducon">Optima International Education</a></blockquote></div>
                </div>
                <div className='mobile'>
                    <div class="fb-page" data-href="https://www.facebook.com/optimaeducon" data-tabs="timeline" data-width="300" data-height="980" data-small-header="true" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="false"><blockquote cite="https://www.facebook.com/optimaeducon" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/optimaeducon">Optima International Education</a></blockquote></div>
                </div>
            </div>
        </div>

    )

}

