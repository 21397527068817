import React from 'react'
import { Link } from 'react-router-dom';

import { Menu } from './Menu';


export const Header = () => {
    return (
        <header>
            <div className="top-ban py-1">
                <div className="container">
                    <div className="d-flex">
                        <div>
                            <p className="my-0"><small>riteshbasnet123@gmail.com</small></p>
                        </div>
                        <div>
                            <p className="my-0"><small>Test Preparation Classes : IELTS  |  PTE</small></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="logo-menu py-1">
                <div className="container">
                    <div className="logo-menu-container">
                        <div>
                            <div className="logo">
                                {
                                    <Link to="/"><img src="images/logo.png" /></Link>
                                }
                            </div>
                        </div>
                        <div>
                            {
                                <Menu></Menu>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}
