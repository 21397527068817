import React from 'react'
import { Slider } from './Slider';
import { SliderData } from './SliderData';
import { WelcomeAbout } from './WelcomeAbout';
import { Country } from './Country';
import { Classes } from './Classes';
import { Universities } from './Universities';
import { Contact } from './Contact';
import { Gallery } from '../Pages/Gallery';
import { PageCountryList } from '../Pages/AuCountry';
import { PageUsaCountryList } from '../Pages/UsaCountry';
import { CanadaCountry } from '../Pages/CanadaCountry';
import { UkCountry } from '../Pages/UkCountry';
import { JapanCountry } from '../Pages/JapanCountry';
import { NewzealandCountry } from '../Pages/NewzealandCountry';
import { DubaiCountry } from '../Pages/DubaiCountry';
import { IeltsClasses } from '../Pages/Ielts';
import { PteClasses } from '../Pages/pte';
import { TofelClasses } from '../Pages/tofel';
import { About } from '../Pages/about';
import { ContactPage } from '../Pages/contact';
import { Comming } from '../Pages/Comming';
import Facebook from '../Component/Facebook';
import Instagram from '../Component/Instagram';

// import { Suspense } from 'react/cjs/react.production.min';
// import { AnimatePresence, motion } from "framer-motion"
import { Routes, Route, useLocation } from 'react-router-dom';
import { SocialMedia } from '../Component/Socialmeda';
import { Staff } from '../Component/Staff';
import { StaffPage } from '../Pages/StaffPage';
// import { EmbeddedPostExample } from '../Component/FacebookPost';

function AnimatedRoute() {
    var location = useLocation();
    return (

        <Routes key={location.pathname} location={location}>
            <Route path="/" element={
                <section>
                    <Slider slides={SliderData}></Slider>
                    <WelcomeAbout title="Welcome To" readmore={true}></WelcomeAbout>
                    <Country></Country>
                    <Classes></Classes>
                    {
                        //<EmbeddedPostExample></EmbeddedPostExample>
                    }
                    <section className='universities-contact'>
                        <div className="">
                            <div className='uni-con'>
                                <div>
                                    <Universities></Universities>
                                </div>
                                <div>
                                    <Contact></Contact>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className='text-center bg-primary py-5'>
                        <div className="container">
                            <div className="socialMediaOuter">
                                <SocialMedia></SocialMedia>
                                <Staff></Staff>
                            </div>
                        </div>
                    </div>

                </section>
            }></Route>
            <Route path="/australia" element={
                <section>
                    <PageCountryList></PageCountryList>
                    <Country></Country>
                    <section className='universities-contact'>
                        <div className="">
                            <div className='uni-con'>
                                <div>
                                    <Universities></Universities>
                                </div>
                                <div>
                                    <Contact></Contact>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            }></Route>
            <Route path="/usa" element={
                <section>
                    <PageUsaCountryList></PageUsaCountryList>
                    <Country></Country>
                    <section className='universities-contact'>
                        <div className="">
                            <div className='uni-con'>
                                <div>
                                    <Universities></Universities>
                                </div>
                                <div>
                                    <Contact></Contact>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            }></Route>
            <Route path="/canada" element={
                <section>
                    <CanadaCountry></CanadaCountry>
                    <Country></Country>
                    <section className='universities-contact'>
                        <div className="">
                            <div className='uni-con'>
                                <div>
                                    <Universities></Universities>
                                </div>
                                <div>
                                    <Contact></Contact>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            }></Route>
            <Route path="/uk" element={
                <section>
                    <UkCountry></UkCountry>
                    <Country></Country>
                    <section className='universities-contact'>
                        <div className="">
                            <div className='uni-con'>
                                <div>
                                    <Universities></Universities>
                                </div>
                                <div>
                                    <Contact></Contact>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            }></Route>
            <Route path="/japan" element={
                <section>
                    <JapanCountry></JapanCountry>
                    <Country></Country>
                    <section className='universities-contact'>
                        <div className="">
                            <div className='uni-con'>
                                <div>
                                    <Universities></Universities>
                                </div>
                                <div>
                                    <Contact></Contact>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            }></Route>
            <Route path="/dubai" element={
                <section>
                    <DubaiCountry></DubaiCountry>
                    <Country></Country>
                    <section className='universities-contact'>
                        <div className="">
                            <div className='uni-con'>
                                <div>
                                    <Universities></Universities>
                                </div>
                                <div>
                                    <Contact></Contact>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            }></Route>
            <Route path="/newzealand" element={
                <section>
                    <NewzealandCountry></NewzealandCountry>
                    <Country></Country>
                    <section className='universities-contact'>
                        <div className="">
                            <div className='uni-con'>
                                <div>
                                    <Universities></Universities>
                                </div>
                                <div>
                                    <Contact></Contact>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            }></Route>
            <Route path="/ielts" element={
                <section>
                    <IeltsClasses></IeltsClasses>
                    <Classes></Classes>
                    <section className='universities-contact'>
                        <div className="">
                            <div className='uni-con'>
                                <div>
                                    <Universities></Universities>
                                </div>
                                <div>
                                    <Contact></Contact>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            }></Route>
            <Route path="/pte" element={
                <section>
                    <PteClasses></PteClasses>
                    <Classes></Classes>
                    <section className='universities-contact'>
                        <div className="">
                            <div className='uni-con'>
                                <div>
                                    <Universities></Universities>
                                </div>
                                <div>
                                    <Contact></Contact>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            }></Route>
            <Route path="/tofel" element={
                <section>
                    <TofelClasses></TofelClasses>
                    <Classes></Classes>
                    <section className='universities-contact'>
                        <div className="">
                            <div className='uni-con'>
                                <div>
                                    <Universities></Universities>
                                </div>
                                <div>
                                    <Contact></Contact>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            }></Route>
            <Route path="/about" element={
                <section>
                    <About></About>
                    <section className='universities-contact'>
                        <div className="">
                            <div className='uni-con'>
                                <div>
                                    <Universities></Universities>
                                </div>
                                <div>
                                    <Contact></Contact>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            }></Route>
            <Route path="/staff" element={
                <section>
                    <StaffPage></StaffPage>
                    <section className='universities-contact'>
                        <div className="">
                            <div className='uni-con'>
                                <div>
                                    <Universities></Universities>
                                </div>
                                <div>
                                    <Contact></Contact>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            }></Route>
            <Route path="/Gallery" element={
                <section>
                    <Gallery></Gallery>
                    <section className='universities-contact'>
                        <div className="">
                            <div className='uni-con'>
                                <div>
                                    <Universities></Universities>
                                </div>
                                <div>
                                    <Contact></Contact>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            }></Route>
            <Route path="/contact" element={
                <section>
                    <ContactPage></ContactPage>
                </section>
            }></Route>
            <Route path="/comming" element={
                <section>
                    <Comming></Comming>
                </section>
            }></Route>
            <Route path='/facebook' element={<Facebook></Facebook>}></Route>
            <Route path='/instagram' element={<Instagram></Instagram>}></Route>

        </Routes>

    )
}

export default AnimatedRoute