import React from 'react'
export const NewzealandCountry = () => {
    window.scrollTo(0, 0);
    return (
        <div>
            <section className='innerslider'>
                <img src='images/newzealand-slider.jpg'></img>
                <div className='caption bg-primary text-light'>
                    <h2 className='mt-0 mb-1'>NewZealand </h2>
                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.</p>
                </div>
            </section>
            <section className='education-system-section py-5 bg-light'>
                <div className="container">
                    <div className='d-grid'>
                        <div>
                            <h2 className='mb-0 mt-0'>The Education System</h2>
                            <h1 className='mb-0 mt-0 text-primary'>Study In NewZealand from Nepal</h1>
                            <p><strong>Why Study in Australia from Nepal is a great choice?
                            </strong></p>
                            <ul>
                                <li>Universities in Australia are rated amongst the best in the world, with 8 of them ranked in the Top 100 </li>
                                <li>The Australian University system is even better than Germany and New Zealand</li>
                                <li>Most universities are located in capital cities that rank in the top 30 most liveable cities for students</li>
                                <li>Over $200,000,000 is spent annually on international scholarships by the Government</li>
                                <li>A huge number of international students choose Australia each year as their study destination, owing to its welcoming environment.</li>
                            </ul>
                            <p>Rest assured, with a great education system in place, you can live, study and experience a culture with plenty of tourist attractions and well-equipped transportation facilities.</p>
                        </div>
                        <div>
                            <img className='main-img' src="images/education-system1.jpg" alt="" />
                        </div>
                    </div>
                    <div className="icon-boxes ">
                        <div className="icon-box bg-primary">
                            <img src="images/icon1.png" />
                            <p>Undergrads</p>
                        </div>
                        <div className="icon-box bg-primary">
                            <img src="images/icon2.png" />
                            <p>Postgrads</p>
                        </div>
                        <div className="icon-box bg-primary">
                            <img src="images/icon3.png" />
                            <p>Diploma</p>
                        </div>
                        <div className="icon-box bg-primary">
                            <img src="images/icon4.png" />
                            <p>Foundation</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="visaRequiremnt py-5">
                <div className="container">
                    <div className="text-center">
                        <h2 className="text-primary mt-0">Visa requirements to study in NewZealand from Nepal</h2>
                        <p>Australia has a stringent visa system in place, but with the right guidance, you will be able to walk your way through the process with ease. </p>
                        <p>To acquire the student visa, you must have enrolled in a course of study in Australia and show evidence of your financial capacity to pay the course fee and support yourself throughout your stay. Students under the age of 18 can also bring their parents to stay with them. Here is the process flow to apply for and obtain a student visa. </p>
                    </div>
                    <div className="d-grid">
                        <div>
                            <img src="images/visa-img.jpg" />
                        </div>
                        <div>
                            <div>
                                <p><strong>Here's how it works:</strong></p>
                                <ul>
                                    <li>Register with AECC Global and talk with an education counsellor to study in Australia from Nepal.</li>
                                    <li>Apply to institutions with our help (We have a great working relationship with every one of them)</li>
                                    <li>Take IELTS test</li>
                                    <li>Receive offer letter from the institution</li>
                                    <li>Collect your Australian Student visa documents</li>
                                    <li>Pay tuition fee and receive your eCoE</li>
                                    <li>Pay tuition fee and receive your eCoE</li>
                                    <li>Collect visa grant letter</li>
                                </ul>
                                <br />
                                <a className="btn btn-primary py-1 px-2 mt-2">Know More About Dubai Visa</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
