import React from 'react'

export const Comming = () => {
    return (
        <div>
            <section className='comming_soon_section'>
                <h1>Comming Soon</h1>
            </section>
        </div>
    )
}
